
.calendar
  width: 254px
  padding: 0 16px
  .header-cell, .body-cell
    flex: 0 0 14.28%
    outline: none
    text-align: center
    .date-number
      align-items: center
      padding: 0
  .controls
    align-items: center
    color: '#191C1C'
    display: flex
    justify-content: space-between
    outline: none

    .icon
      cursor: pointer

    .disabled
      opacity: 0.38
      pointer-events: none

    button
      background: transparent
      border: none
      cursor: pointer
      display: block
      outline: none
      &:disabled
        cursor: not-allowed
        opacity: 0.2

      i
        font-size: 36px
        color: #006298
        padding: 10px

    .month-title
      font-size: 14px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      font-weight: 500

    .month-name
      display: block
      font-weight: 700

    .month-year
      color: '#2C3B49'
      display: block
      font-size: .938rem

  .header
    display: flex
    justify-content: space-between
    margin-top: 1rem
    margin-bottom: 1rem

  .day-label
    color: #192129
    font-size: 0.875rem
    font-weight: 500

  .body
    padding: 0

  .body-row
    display: flex
    justify-content: space-between

  .body-cell
    color: #006298
    font-size: 1rem
    position: relative

    span
      &:focus
        outline: none

    &:after
      content: ''
      display: block
      padding-bottom: 100%

    .date-number
      border-radius: 50%
      display: flex
      height: 100%
      align-items: center
      justify-content: center
      left: 0
      padding: 0
      position: absolute
      top: 0
      transition: background 0.2s
      width: 100%
      color: #B9C5D1
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
        display: block

    &.prev-month-day
      color: #B9C5D1

    &.prev-month-day, &.next-month-day
      font-weight: normal

    &.current-day
      .date-number
        outline: none
        justify-content: center
    &.has-events
      &:hover
        border: 1px solid #006298
        cursor: pointer
        border-radius: 50%

      .date-number
        flex-flow: column
        justify-content: center
        outline: none
        color: #192129

    &.selected.has-events
      background: #006298
      cursor: auto
      border-radius: 50%
      .date-number
        color: #FFFFFF
        border: none
        outline: none
        justify-content: center
