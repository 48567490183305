
.chatContainer {
  display: "flex";
  flex-direction: "column";
  height: "100vh";
  width: "100%";
}
::-ms-reveal {
  display: none;
}

strong {
  font-weight: 600;
}

.chatContainer.minimized{
  height: auto;
}
.minimized{
  display: 'none';
}

.disclaimer {
  position: "fixed";
  top: 80;
  z-index: 1;
  width: "100%";
  padding: 16;
  font-size: 12;
  border-top: "1px solid #B9C5D1";
  border-bottom: "1px solid #B9C5D1";
  text-align: "center";
  color: "#192129";
  background: " var(--White, #FFF)"
};

.minimized .disclaimer,
.minimized .chatBody {
  display:'none'
};

.chatBody {
  margin-top: 60;
  flex-grow: 1;
  display: "flex";
  flex-direction: "column";
  justify-content: "flex-end";
  overflow-Y: "auto",
}
